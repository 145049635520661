require('dotenv').config();
import axios from 'axios';

import Cookies from "universal-cookie";
const cookies = new Cookies();

const baseURL = process.env.RAZZLE_API_BACKEND_URL;
const wordpressBaseURL = process.env.RAZZLE_WORDPRESS_BACKEND_URL;


/**
 * Available Server URL'S
 * @returns {{Accept: string, "Content-Type": string}}
 */
// const baseURL = 'https://beta-api.farmersfreshmeat.com/api/';
// const wordpressBaseURL = "https://blog.farmersfreshmeat.com/";

// const baseURL = 'https://upay.qualwebs.com/ffkv2/public/api/';
// const baseURL = 'http://localhost:8000/api/';
// const baseURL = 'http://127.0.0.1:3333/api/v1/';
// const baseURL = 'https://v2api.farmersfreshkitchen.com/api/';

/**
 * API Request Header
 * @returns {{Accept: string, "Content-Type": string}}
 */
const headers = () => {
    const headersData = {
        'Content-Type': 'application/json',
        "Accept": "application/json",
    };
    const token = cookies.get("token");
    if (token) {
        headersData.Authorization = `Bearer ${token}`;
    }
    return headersData;
};

const request = (method, path, body, token) => {

    // if (path.includes("common/products") ||
    //     path.includes("common/categories") ||
    //     path.includes("add-to-cart") ||
    //     path.includes("user/cart/") ||
    //     path.includes('user/cart-items/') ||
    //     path.includes('common/product/') ||
    //     path.includes('common/category-products') ||
    //     path.includes('orders/') ||
    //     path.includes('guest-checkout-v2') ||
    //     path.includes('sign-up/') ||
    //     path.includes('login') ||
    //     path.includes('add-list') ||
    //     path.includes('get-all-lists') ||
    //     path.includes('get-list-item') ||
    //     path.includes('delete-list') ||
    //     path.includes('create-list') ||
    //     path.includes('update-list') ||
    //     path.includes('delete-list') ||
    //     path.includes('square/add-card') ||
    //     path.includes('get-cards-v2') ||
    //     path.includes('set-card-default') ||
    //     path.includes('delete-card') ||
    //     path.includes('auth/me') ||
    //     path.includes('auth/my-orders/') ||
    //     path.includes('order-items') ||
    //     path.includes('update-profile') ||
    //     path.includes('change-password') ||
    //     path.includes('catering-items') ||
    //     path.includes('single-catering-item') ||
    //     path.includes('catering-place-order') ||
    //     path.includes('contact-us') ||
    //     path.includes('career-apply') ||
    //     path.includes('common/viewed-products') ||
    //     path.includes('user/cart/') ||
    //     path.includes('restaurant-timing')
    // ) {
    //
    //     var url = `http://localhost:8000/api/${path}`
    //
    // }else{
    //
    // }

    var url = `${baseURL}${path}`;
    if (path.startsWith("wp-json")){
        url = `${wordpressBaseURL}${path}`;
    }

    const options = {method, url, headers: headers(token)};
    if (body) {
        options.data = body;
    }

    return axios(options);
};

export default class API {

    //common
    getProducts(data) {
        return request('POST', 'common/products', data);
    }

    getRestaurantAvailableProducts(restId) {
        return request('GET', 'grocery-items/'+restId, null);
    }

    // getQuickProducts(data) {
    //     return request('POST', 'common/quick-products', data);
    // }

    getOrderItems(token, id) {
        return request('GET', 'order-items/' + id, null, token);
    }

    // getSectionProducts() {
    //     return request('GET', 'common/section-products');
    // }

    getSingleProduct(slug,restaurant) {
        return request('GET', 'common/product/' + slug + "?restaurant_id="+restaurant);
    }

    getSingleCateringProduct(slug,restaurant) {
        return request('GET', 'single-catering-item/' + slug  + "?restaurant_id="+restaurant);
    }

    getSingleRestaurant(id) {
        return request('GET', 'get-single-restaurant/' + id);
    }

    getRestaurantAvailability(id) {
        return request('GET', 'restaurant-timing/' + id);
    }

    getAllRestaurants() {
        return request('GET', 'get-restaurants');
    }

    rateProduct(token, data) {
        return request('POST', 'rate-product', data, token);
    }

    getCategories(data) {
        return request('POST', 'common/categories', data);
    }

    getFilterProduct(data) {
        return request('POST', 'common/products', data);
    }

    getBestSellerProducts(data) {
        return request('POST', 'common/bestseller-products', data);
    }


    getCategoryProducts(data) {
        return request('POST', 'common/category-products', data);
    }

    getCateringRelatedProducts(data) {
        return request('POST', 'common/catering-related-products', data);
    }

    getRecentViewedProducts(data) {
        return request('POST', 'common/viewed-products', data);
    }

    //Auth
    signUp(data, cart_id, list_id) {
        return request('POST', 'user/sign-up/' + cart_id + '/' + list_id, data);
    }

    // googleAuth(data, cart_id, list_id) {
    //     return request('POST', 'user/google-login/' + cart_id + '/' + list_id, data);
    // }

    forgot(data) {
        return request('POST', 'v2/reset-password', data);
    }

    subscription(data) {
        return request('POST', 'newsletter-subscribe', data);
    }

    changePassword(data) {
        return request('POST', 'reset-password', data);
    }

    login(data) {
        return request('POST', 'grocery-login', data);
    }

    authME(token) {
        return request('GET', 'auth/me', null, token);
    }

    updateProfile(data, token) {
        return request('POST', 'update-profile', data, token);
    }

    resetPassword(data, token) {
        return request('POST', 'v2/change-password', data, token);
    }

    addToCart(data, cart_id, token,preference) {
        data['cart_id'] = cart_id;
        const address = JSON.stringify(cookies.get("delivery_address"));
        const coupon_applicable = cookies.get("free_delivery_applicable") || false;
        return request('POST', 'add-to-cart?preference='+preference+"&preferred_address="+address+"&coupon_applicable="+coupon_applicable, data, token);
    }

    removeFromCart(item_id) {
        return request('DELETE', 'user/cart-items/' + item_id);
    }

    clearCart(cart_unique_id, token) {
        return request('DELETE', 'user/cart/' + cart_unique_id, null, token);
    }

    cartItemSchedule(data) {
        return request('POST', 'user/cart-items-schedule', data, null);
    }

    getCartItems(cart_id, token) {
        return request('GET', 'user/cart/' + cart_id +"?menu_id=3", null, token);
    }

    getRestaurantCartUnavailableItems(data) {
        return request('POST', 'check-item-availibility',data, null);
    }

    assignCartItemToRestaurant(data) {
        return request('POST', 'remove-unavailable-cart-items',data, null);
    }

    cartProductAvailability(cart_id, data) {
        return request('POST', 'user/cart-product-availability/' + cart_id, data, null);
    }

    addToList(data, token) {
        return request('POST', 'add-list', data, token);
    }

    getCities() {
        return request('GET', 'common/cities', null, null);
    }

    getStates() {
        return request('GET', 'get-states', null, null);
    }

    getCitiesByState(state) {
        return request('GET', 'get-cities/'+ state, null, null);
    }

    getOffers($preference,$email,$phone) {
        return request('GET', 'get-farmers-coupons?target_audience=ffm&&preference='+$preference+ "&&email=" + $email + "&&phone=" + $phone, null);
    }

    applyCoupon(data) {
        return request('POST', 'apply-farmers-coupons', data, null);
    }

    removeCoupon(data) {
        return request('POST', 'remove-farmers-coupons', data, null);
    }

    checkCouponAvailability(data) {
        return request('POST', 'check-coupon-availability', data, null);
    }

    addPaymentCard(data, token) {
        return request('POST', 'square/add-card', data, token);
    }

    addCardknoxPaymentCard(data, token) {
        return request('POST', 'cardknox/add-card', data, token);
    }

    addEBTCard(data) {
        return request("POST", `payment/ebt/card/store`, data);
    }

    getPaymentCards(token) {
        return request('GET', 'get-cards-v3', null, token);
    }

    deletePaymentCard(id, token) {
        return request('POST', 'delete-card', {card_id : id}, token);
    }

    makeCardDefault(id, token) {
        return request('GET', 'set-card-default/' + id, null, token);
    }

    makeAddressDefault(id, token) {
        return request('GET', 'user/mark-address-default/' + id, null, token);
    }

    //LIST
    newList(data, token) {
        return request('POST', 'create-list', data, token);
    }

    updateList(data, token, list_id) {
        data['list_type_id'] = list_id ;
        return request('POST', 'update-list', data, token);
    }

    deleteList(list_id, token) {
        return request('GET', 'delete-list/' + list_id, null, token);
    }

    getUserList(token, list_unique_id) {
        return request('POST', 'get-all-lists' , {list_unique_id : list_unique_id}, token);
    }

    // getListItems(list_unique_id,token) {
    //     return request('POST', 'get-all-lists', {list_unique_id : list_unique_id}, token);
    // }

    getUserListItems(list_id, token, data) {
        return request('POST', 'get-list-item/' + list_id, data, token);
    }

    removeFromList(item_id) {
        return request('POST', 'delete-list-item',{item_id : item_id});
    }

    //order
    updateItemNote(item_id, data) {
        return request('POST', 'user/cart-items/' + item_id, data, null);
    }

    placeOrder(path,data,token) {
        return request('POST', path, data, token);
    }

    placeCateOrder(path,token, data,preference) {
        return request('POST', path + "?preference="+preference, data, token);
    }

    getFilterCateringProduct(restaurant_id) {
        return request('GET', 'catering-items/'+restaurant_id);
    }

    getOrders(data, token, type,preference) {
        return request('POST', 'auth/my-orders/'+type, data, token);
    }

    getCateringOrders(data, token, preference) {
        return request('POST', 'user/catering-orders/' + preference, data, token);
    }

    getSingleOrder(id) {
        return request('GET', 'orders/' + id);
    }

    getSingleOrderByUUID(uuid) {
        return request('GET', 'uuid-orders/' + uuid);
    }

    contactUs(data) {
        return request('POST', 'contact-us', data);
    }

    careerAction(data) {
        return request('POST', 'career-apply', data);
    }

    getAllBlog(page) {
        return request('GET', 'wp-json/wp/v2/posts?_embed&per_page=10&page='+page, null);
    }

    getSidebarAllBlog() {
        return request('GET', 'wp-json/wp/v2/posts?_embed&per_page=100&page=1', null);
    }

    getFilterTagBlog(page,tag_id) {
        return request('GET', 'wp-json/wp/v2/posts?_embed&per_page=10&page='+page+'&'+'tags='+tag_id, null);
    }

    getFilterCategoryBlog(page,category_id) {
        return request('GET', 'wp-json/wp/v2/posts?_embed&per_page=10&page='+page+'&'+'categories='+category_id, null);
    }

    getSingleBlog(slug) {
        return request('GET', 'wp-json/wp/v2/posts/?slug='+slug+"&_embed", null);
    }

    getAllTags() {
        return request('GET', 'wp-json/wp/v2/tags', null);
    }

    deliveryEstimations(data) {
        return request('POST', 'get-delivery-estimates', data, null);
    }

    validateDeliveryAddress(data) {
        return request('POST', 'validate-delivery-address', data, null);
    }

    cateringDeliveryEstimations(data) {
        return request('POST', 'catering-delivery-estimates', data, null);
    }

    changeCartPreference(data) {
        const address = JSON.stringify(cookies.get("delivery_address"));
        data['preferred_address'] = address;
        return request('POST', 'change-cart-preference', data, null);
    }

    deliveryDriverTip(data) {
        return request('POST', 'delivery-tip', data, null);
    }

    deliveryDriverTipRemoved(data) {
        return request('POST', 'remove-delivery-tip', data, null);
    }

    generateCardknoxToken(data) {
        return request('POST', 'payment/cardknox-initiate', data, null);
    }

    // EBT PAYMENT
    initiateEbtTransaction(data){
        return request('POST', 'payment/ebt/initiate', data);
    }

    validateEbtItems(data){
        return request('GET', `payment/ebt/items/validate/${data}`);
    }

    // EBT PAYMENT CARD
    validateEbtCard(data){
        return request('POST', `payment/ebt/card/read`, data);
    }

    validateGuestEbtCard(data){
        return request('POST', `payment/ebt/card/balance`, data);
    }

    promotionalSubscribe(data) {
        return request('POST', 'contact-promotional-subscribe', data);
    }

}
